@import '../../styles/variables';

.login {
  display: flex;
  flex-direction: column;
  padding: $px-40;
  width: $px-360;
  background: $white;
  box-shadow: 0 $px-4 $px-8 $-px-2 rgba(16, 24, 40, 0.1),
    0 $px-2 $px-4 $-px-2 rgba(16, 24, 40, 0.06);
  border-radius: $px-12;

  .logo,
  h2,
  h4 {
    text-align: center;
  }

  .logo {
    margin-bottom: $px-20;

    img {
      margin: $px-3;
    }
  }

  h2 {
    margin-bottom: $px-12;
  }

  h4 {
    font-size: $px-16;
    line-height: $px-24;
    color: $gray-500;
    margin: 0 0 $px-10 0;
    padding: 0;
  }

  strong {
    font-family: 'Public Sans Medium';
    font-size: $px-14;
    line-height: $px-20;
    color: $gray-700;
    margin: $px-20 0 0 0;

    input {
      margin-right: $px-8;
    }
  }

  .k-button {
    margin: $px-18 0 0 0;

    &.plain {
      margin: $px-16 0 0 0;
      color: $gray-500;

      .k-button-text {
        display: flex;
      }
    }

    img {
      margin-right: $px-10;
    }
  }

  .footer {
    margin: $px-24 0 0 0;
    font-size: $px-14;
    line-height: $px-20;
    color: $gray-500;
    text-align: center;

    span {
      color: $primary-700;
      font-family: 'Public Sans Medium';
    }
  }
}
