@import '../../styles/variables.scss';

.search {
  display: flex;
  min-width: $px-260;
  & span.k-floating-label-container {
    width: 100%;
  }
  input.k-input {
    margin: 0;
    padding: $px-4 $px-8;
  }
  img {
    box-sizing: content-box;
    height: $px-18;
    margin-top: $px-5;
    width: $px-18;
  }
}
