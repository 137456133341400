﻿$page_background_color: #e6eff5;
$content_background_color: #fff;

$instruction_background_color: #d6eef9;
$instruction_color: #074a73;

$color_magenta: #e5097f;
$color_default: #0078bf;
$color_grey: #c1c1c1;

$item_number_color: $color_default;
$assignment_bgcolor: $content_background_color;

$primary_bgcolor: $color_default;
$primary_color: #fff;
$primary_shadow_color: #ddd;

$secondary_color: $color_default; 
$infobox_bgcolor: #e6e6e6;

$minor_color: #898989;
$minor_background_color: #eee;

$line-color: #ebecec;

$modal_header_color: #222;

$navigation_OK_background_color: #adddf2;

$default_fontSize: 16px;

$breakpoints: (
    'small': (min-width: 992px),
    'medium': (min-width: 1024px),
    'large': (min-width: 1540px)
);

$proctoring-width: 200px;

$highlighter-selected-color: #ffe9cd;