﻿.filling-item {
    .filling-item-select {
        padding: .275rem 1.75rem .275rem .75rem;
        font-weight: 400;
        line-height: 1.5;
        vertical-align: middle;
        background-color: #fff;
        border: 1px solid #ced4da;
        border-radius: .25rem;
    }

    input[type=radio] {
        top: 5px;
        left: 4px;
        position: absolute;
        margin: 5px 5px 0px 5px;
        visibility: hidden;
    }

    .itemOptionKey {
        top: 0;
        left: 0;
        position: absolute;
        width: 32px;
        height: 32px;
        display: inline-block;
        border-radius: 50%;
        border: 1px solid $minor_color;
        font-size: 16px;
        text-align: center;
        line-height: 30px;
        color: $minor_color;
        padding-left: 1px;
        z-index: 100;
    }

    .clearAnswer {
        width: 100%;
        height: 100%;
        color: #fff;
        position: absolute;
        left: 0px;
        top: 0px;
        cursor: pointer;

        &::before {
            content: attr(value);
            width: 32px;
            height: 32px;
            position: absolute;
            top: 0;
            cursor: pointer;
            background-color: $item_number_color;
            border-radius: 50%;
            border: 1px solid $item_number_color;
            font-size: $default_fontSize;
            z-index: 101;
            color: #fff;
            text-align: center;
            line-height: 30px;
            font-weight: bold;
        }

        &:hover {
            background-position: 0 -10px;
        }
    }

    .clearAnswer-inline {
        display: inline;
    }

    .option-box {
        position: relative;
        padding: 5px 10px 5px 40px;
        min-height: 39px;

        label {
            margin-bottom: 0;
            cursor: pointer;
        }
    }

    &.hide-distractor-keys {
        .clearAnswer {
            &::before {
                background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIzMSIgaGVpZ2h0PSIzMSIgcng9IjE1LjUiIGZpbGw9IndoaXRlIi8+CjxjaXJjbGUgY3g9IjE2IiBjeT0iMTYiIHI9IjYuNCIgZmlsbD0iIzAwNzhCRiIvPgo8cmVjdCB4PSIwLjUiIHk9IjAuNSIgd2lkdGg9IjMxIiBoZWlnaHQ9IjMxIiByeD0iMTUuNSIgc3Ryb2tlPSIjMDA3OEJGIi8+Cjwvc3ZnPgo=) no-repeat;
                @include hide-distractor-keys-shared(32px, 2px);
            }
        }

        .itemOptionKey {
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIzMSIgaGVpZ2h0PSIzMSIgcng9IjE1LjUiIGZpbGw9IndoaXRlIi8+CjxyZWN0IHg9IjAuNSIgeT0iMC41IiB3aWR0aD0iMzEiIGhlaWdodD0iMzEiIHJ4PSIxNS41IiBzdHJva2U9IiM4OTg5ODkiLz4KPC9zdmc+Cg==);
            @include hide-distractor-keys-shared(32px, 2px);
        }
    }
}
