@import '../../styles/variables.scss';

.left-bar-template {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 0;
  // left menu
  & > div {
    background: $gray-50;
    display: flex;
    flex-shrink: 0;
    width: $px-280;
    min-height: $px-900;
    flex-direction: column;
    padding: 0;
    h4 {
      font-family: 'Public Sans Semi Bold';
      font-size: $px-12;
      line-height: $px-18;
      color: $gray-400;
      text-transform: uppercase;
      margin: $px-36 0 $px-6 0;
      padding: 0 $px-28;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0 $px-16;
      li {
        a {
          align-items: center;
          border-radius: $px-6;
          color: $gray-700;
          display: flex;
          flex-direction: row;
          font-family: 'Public Sans Medium';
          font-size: $px-14;
          gap: $px-8;
          height: $px-40;
          justify-content: space-between;
          line-height: $px-20;
          padding: $px-4 $px-12;
          text-decoration: none;
          & > div {
            display: flex;
            align-items: center;
          }
          .label {
            font-size: $px-12;
          }
          svg {
            margin-right: $px-14;
            stroke: $gray-700;
            width: $px-20;
            height: $px-22;
            fill: none;
          }
          &:hover {
            background: $gray-100;
          }
        }
        &.active {
          a {
            background: $gray-100;
            color: $primary-700;
            .label {
              background: $primary-100;
            }
            svg {
              stroke: $primary-700 !important;
            }
          }
        }
      }
    }
  }
  // content
  & > section {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: $px-36 $px-36 $px-72 $px-36;
    h2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 $px-16 0;
      .k-button {
        margin-left: $px-12;
      }
    }
    .search {
      margin: 0 0 $px-30 0;
    }
    .k-tabstrip {
      height: 100%;
      .k-animation-container {
        width: 100%;
        height: 100%;
        .k-child-animation-container {
          height: 100%;
          & > div {
            height: 100%;
          }
        }
      }
    }
  }
}
