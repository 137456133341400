@import 'variables';

/*********** TinyMCE ***********/
.tox.tox-tinymce-aux {
  z-index: 13000;
}

.tox-tbtn.tox-tbtn--disabled {     
  background: none !important; 
}

.tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) {
  border-right: 1px solid $gray-300 !important;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  padding: 0 !important;
}

.tox .tox-toolbar__group {
  margin: 8px 0 !important;
  padding: 0 4px !important;
}

.tox .tox-tbtn {
  margin: 0 2px !important;
  height: 24px !important;
}

// disabled
.tox.tox-tinymce--disabled {
  .tox-edit-area__iframe {
    opacity: 0.6;
  }
}
