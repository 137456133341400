@import '../../../styles/variables';
@import '../../../styles/mixins';

.k-dialog-wrapper.create-update-event-modal {
  display: flex;
  flex-direction: column;
  overflow: scroll;

  .k-dialog {
    font-family: 'Public Sans Medium';
    color: $gray-700 !important;

    .k-dialog-content {
      overflow: hidden;

      &.k-window-content {
        &::after {
          display: none;
        }
      }

      nav {
        position: fixed;
        margin-top: $-px-54;
      }

      p {
        color: $gray-500 !important;
        font-family: 'Public Sans Regular';
        margin: $px-6 0 $px-14 0;
        padding: 0;
      }

      .k-grid-container {
        min-height: auto;
        max-height: calc(90vh - 480px);

        .k-grid-content {
          @include scrollbar();
        }

        .k-grid-table {
          padding-bottom: $fade-gradient-height;
        }

        @include fade-gradient($fade-gradient-height);
      }
    }
  }

  &.with-navigation {
    .k-dialog-content {
      & > div {
        padding-left: $px-260 !important;
      }
    }
    .k-dialog-title {
      padding-left: $px-260 !important;
    }
  }

  .search {
    margin-bottom: $px-12;
  }

  .display-flex {
    gap: $px-16;
    align-items: center;
    justify-content: space-between;
    &.with-image {
      p {
        margin: 0 !important;
      }
    }
    & > div {
      flex: 1;
    }
    .k-dropdownlist {
      width: auto;
    }
  }

  .switch {
    background: $gray-50;
    padding: $px-4;
    border: 1px solid $gray-100;
    border-radius: $px-8;
    margin: $px-5 0;
    .k-button {
      color: $gray-500;
      flex: 1;
      border: none;
      background: transparent;
      &.active {
        background-color: $white;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
          0px 1px 2px rgba(16, 24, 40, 0.06);
        color: $gray-700;
      }
    }
  }

  .fadeOut {
    .display-flex {
      opacity: 0.4;
      pointer-events: none !important;
      &:first-child {
        opacity: 1;
        pointer-events: auto !important;
      }
    }
  }

  .schedule-event,
  .proctoring {
    width: $px-680;
    display: flex;
    gap: $px-18;
    flex-direction: column;
    padding-right: $px-8;
    .display-flex.flex-column {
      align-items: unset;
      p {
        margin: $px-8 0 $-px-4 0;
      }
    }
    .switcher-column {
      max-width: fit-content;
    }
    &.instruction {
      .controls {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
      }
    }
    .proctoring-text {
      white-space: normal;
      max-height: $px-100;
    }

    div.error-message {
      color: red;
      font-size: $px-12;
    }

    padding-bottom: $fade-gradient-height;

    @include fade-gradient($fade-gradient-height);
    @include scrollbar();
  }

  .classroom-list {
    .cursor-pointer {
      font-family: 'Public Sans Regular' !important;
      color: $gray-500;
      span {
        color: $gray-900;
        font-family: 'Public Sans Medium' !important;
      }
    }
  }
}
