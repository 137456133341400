﻿.ui-keyboard {
    position: fixed;
    right: 30px;
    text-align: right;
    top: 85px;
    display: inline-block;
    max-width:370px;
}

.ui-keyboard-button {
    border-radius: 0.25rem;
    border: 1px solid #999;
}

.ui-keyboard-actionkey {
    line-height: 1.5;
    padding: 0.375rem 0.75rem;

    .ui-keyboard-text {
        line-height: 1.5;
        position: relative;
        top: -5px;
    }
}

.ui-keyboard-accept {
    background-color: $primary_bgcolor;
    border-color: $primary_bgcolor;
    color: #fff;
}

.ui-keyboard-cancel {
    color: #666;
}