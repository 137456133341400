@import 'variables';

@mixin scrollbar() {
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: $scrollbar-width;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100vw;
    background-color: $gray-300;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $gray-400;
  }
}

@mixin fade-gradient($height) {
  &::after {
    content: ' ';
    display: block;
    height: $height;
    width: calc(100% - $scrollbar-width);
    position: absolute;
    bottom: 0;
    right: $scrollbar-width;
    background: linear-gradient(transparent, $white);
    pointer-events: none;
  }
}
