﻿.multiple-choice-item {
    .options {
        border: 0px solid black;
    }

    .option-item {
        margin-top: 2px;
        padding: 5px 10px 5px 90px;
        position: relative;
        border-radius: 5px;
        -moz-border-radius: 5px;
        display: block;
        min-height: 24px;

        P {
            margin: 0 0 0 0;
            padding: 0 0 0 0;
        }

        input[type=radio] {
            top: 5px;
            left: 54px;
            position: absolute;
            margin: 5px 5px 0px 5px;
            visibility: hidden;
        }

        input[type=checkbox] {
            top: 5px;
            left: 20px;
            position: absolute;
            margin: 5px 5px 0px 5px;
            visibility: hidden;
        }

        input[type=checkbox]:checked {
            width: 25px;
            height: 25px;
            position: absolute;
            left: 49px;
            top: -1px;
            cursor: pointer;
            background-color: $item_number_color;
            border-radius: 50%;
            border: 1px solid $item_number_color;
            z-index: 101;
            color: #fff;
            text-align: center;
            line-height: 30px;
            font-weight: bold;
            visibility: hidden;

            &:after {
                content: attr(key);
                display: block;
                width: 25px;
                height: 25px;
                position: absolute;
                left: 0;
                top: 0;
                cursor: pointer;
                background-color: $item_number_color;
                border-radius: 50%;
                border: 1px solid $item_number_color;
                z-index: 102;
                color: #fff;
                text-align: center;
                line-height: 23px;
                font-weight: bold;
                font-size: 14px;
                visibility: visible;
            }
        }

        .itemOptionKey {
            top: 0;
            left: 50px;
            position: absolute;
            width: 32px;
            height: 32px;
            display: inline-block;
            border-radius: 50%;
            border: 1px solid $minor_color;
            font-size: $default_fontSize;
            text-align: center;
            line-height: 30px;
            color: #898989;
            padding-left: 1px;
            z-index: 100;
        }

        .simple-text-item {
            margin: 0px;
            padding: 0px;
            font-weight: normal;
        }

        .itemOptionLabel {
            margin-bottom: 0;
            cursor: pointer;
        }
    }

    .horizontal-item {
        display: inline-block !important;
        margin-right: 2px !important;
    }

    .clearAnswer {
        width: calc(100% - 50px);
        height: 100%;
        color: #fff;
        position: absolute;
        left: 50px;
        top: 0px;
        cursor: pointer;

        &::before {
            content: attr(value);
            width: 32px;
            height: 32px;
            position: absolute;
            top: 0;
            cursor: pointer;
            background-color: $item_number_color;
            border-radius: 50%;
            border: 1px solid $item_number_color;
            font-size: $default_fontSize;
            z-index: 101;
            color: #fff;
            text-align: center;
            line-height: 30px;
            font-weight: bold;
        }
    }

    .clearAnswer-inline {
        display: inline;
    }

    &.multiple-choice-item-row {
        width: 100%;

        .assignment {
            display: inline-block;
            padding: 5px 10px 5px 50px !important;
        }

        .options {
            padding-top: 0;
            display: inline-block;
            text-align: right;

            .horizontal-item {
                padding-left: 65px;
                min-width: 70px;

                input {
                    position: initial;
                }

                p {
                    display: inline-block;
                }
            }
        }
    }

    &.hide-distractor-keys {
        /* radio button */
        .clearAnswer {
            &::before {
                background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIzMSIgaGVpZ2h0PSIzMSIgcng9IjE1LjUiIGZpbGw9IndoaXRlIi8+CjxjaXJjbGUgY3g9IjE2IiBjeT0iMTYiIHI9IjYuNCIgZmlsbD0iIzAwNzhCRiIvPgo8cmVjdCB4PSIwLjUiIHk9IjAuNSIgd2lkdGg9IjMxIiBoZWlnaHQ9IjMxIiByeD0iMTUuNSIgc3Ryb2tlPSIjMDA3OEJGIi8+Cjwvc3ZnPgo=);
                @include hide-distractor-keys-shared(32px, 2px);
            }
        }

        .option-item {
            .itemOptionKey {
                background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIzMSIgaGVpZ2h0PSIzMSIgcng9IjE1LjUiIGZpbGw9IndoaXRlIi8+CjxyZWN0IHg9IjAuNSIgeT0iMC41IiB3aWR0aD0iMzEiIGhlaWdodD0iMzEiIHJ4PSIxNS41IiBzdHJva2U9IiM4OTg5ODkiLz4KPC9zdmc+Cg==);
                @include hide-distractor-keys-shared(32px, 2px);
            }
            /* checkbox */
            input[type=checkbox] {
                margin: 0;
                transform: scale(1);
            }

            input[type=checkbox] + label > .itemOptionKey {
                background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADSSURBVEhL7ZbNCsIwEISTKOLJgwiCBvTkW4iP79m7R8G2iErxUJomcScJfYAk9JQPhmTbstM/2OGWTQMXBK1z0iIIdSomaCD1WGGE5ivSJqyocwCDltSQfjBCc/msXjettbHW4k6S4BwSQin1PR72Fzr0gNGaNqe6+VzdVRlRfd9JuT3T9o7vAS1xIjfauJeD3u5HmIRiFE0xiqYYRVOMoilG0cAI06lzVWZmftyhtxkzQ1W/Q2bwYzERQZmB+cywGzPDZCkIz5c71wFkupDr2PAHFiFEjmIkux8AAAAASUVORK5CYIKQzp5RZsOxpH20XBFTwdvhOS4/8rIQ0/kwNs/D87v+6pfTjEwFfd/maO0eJBDRsUlBkSP5gcp6RkrB++9B2roHmQ7FKNQkF/Zt4eLBivxC/T9CtHYNMhGMUmCTnNu7mbZjHkSK/lkt3cD4AleeeBkL/MQuBS315VxrrEqJLAtFdZOpUDQpcumxl5HZCDYhOL2nnJtNyyMpoalQjPZnX2h/6mNy/jfmmwxztcvL8MwimhScqS/ndnM1UqzEpIB6fX6ef5qibzjIg95R5hZ1xvwRbrwcYmhqEYcUnNy9kY6mqrQQSHEYGjwujuwoocfn50X/NIGwjqHiL6VNCBp3lnGrqQaHLf0tTgpVljjpOF6NevWVN5/9vB6YQQiQQnC0rpQ7zTU47Zmdo5S9K0qcXG+sYn91MUqBQHF4u5t7p2pYV5D5V37Zx/KUFnL3RC0Haotp8GzgfkstLmd2r96Kd1W6nXSercMf1inOEkkLEoC7yI67yJ41AlZSzaz+TVL9Bfj4IHJ7+5s8AAAAAElFTkSuQmCC);
                @include hide-distractor-keys-shared(26px, 4px);
            }

            input[type=checkbox]:checked {
                left: 50px;
                top: 0px;

                &:after {
                    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAKnSURBVEhLvZbfS1NhGMe/5+yH02DmNCxbubZlEBF1UUQUSSFCYpbdddNtV1GhQkFhYSR0U3f9B92klREUBHoRiARBCZabstSRuul+udxxZ9vpec4OxmrTtR38wMM55z0v7+e873l/PIKCrUEQCboaKcxa8HO5ZLRIUST5yiJu3EpRp135WQ9YEKMIUMRZxI3b5xeWvqTT6YyiKPwlZSEIHKIoy3LE0bj7DBX5WWSjm6bFQHhUraUjcjIp2e31p+l2iv8Hh4Vf6E06ow4Ot61OhC1BFxH9WXyaieHJ8CxCq7JWmkvZInJg1BfFteff8OzjHG4NeLQ3uZQlYsnYjyi6XnoQkVIwiAKqzAbtbS4lixSSfJ6LoZskS3EZlUYRV483oL/DrdXIpWTR+M84ugY9WIglUWEQceXYTnS3OGC18CbzLyWJJuZ/4cbAJPyRNZhouDqP1uN26z7QOi1IQVEylUEwzrtILiy5/mISMyEJBtoCLh2px/22jSVMXlGQxrzn9RR6XnkRWPkj8wZWcXNwEr7lBIzUk8vUk752J0TeczYhr2jEG8bQ1yDGfDE8HZlFNJGCPyzh3ttpTAcTMJOk4/AO9FJPipEweUUnHFacbarBWjqDN+NLuDM0hb73PnVR8nC1HqzDgzYXzDQJiiVvzT01FvSed6KZZCtSGu8mlvHhe0j9+nMHavGw3QWLqXgJU7C2nWR3aSaddFara0aAgub9NvRfdGFbRf5FuREbfpajthKPLrhxyl1Nw7kdjzvdBdfJZvB5xCfrITqPhrNFuXBOEaaNMryagquuMltYJBLRuHdXC91ObDrQPKdsVab/lvwNi/h0ktQnnTFkjztuO7OeMywshrScIXsslglNUBHZnKFhPWfYsiyI+6d3XsdwTqfldUj9BtHo61lqc0GrAAAAAElFTkSuQmCC);
                    @include hide-distractor-keys-shared(26px, 4px);
                }
            }

            input[type=checkbox]:checked + label > .itemOptionKey {
                background: none;
            }
        }
    }
}
