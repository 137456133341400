@import '../../../styles/variables.scss';

.event-list {
  h3 {
    font-family: 'Public Sans Medium';
    font-size: $px-18;
    line-height: $px-28;
    color: $gray-900;
    margin: $px-28 0 $px-6 0;
  }
  p {
    padding: 0;
    margin: 0 0 $px-14 0;
    font-family: 'Public Sans Regular';
    font-size: $px-14;
    line-height: $px-20;
    color: $gray-500;
  }
  .label {
    padding: 0 $px-12;
    margin: $px-4 $px-8 $px-4 0;
    display: inline-flex;
    &.bulletpoint {
      padding: 0;
      width: $px-70;
    }
  }
  .privacy {
    img {
      margin: 0 $px-8 0 0;
    }
  }
  &.ACTIVE {
    svg {
      rect {
        fill: $success-500;
      }
    }
  }
  &.PAST {
    svg {
      rect {
        fill: $gray-200;
      }
      path {
        stroke: $gray-500;
      }
    }
  }
  &.UPCOMING {
    svg {
      rect {
        fill: $primary-500;
      }
    }
  }
  .percentage {
    width: 70% !important;
  }
}
