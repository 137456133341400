@import '../../styles/variables';
@import '../../styles/mixins';

.k-dialog-wrapper.add-questions-modal {
  .k-dialog {
    .k-dialog-content {
      &.k-window-content {
        &::after {
          display: none;
        }
      }

      .k-grid-container {
        min-height: auto;
        max-height: calc(90vh - 480px);

        .k-grid-content {
          @include scrollbar();
        }

        .k-grid-table {
          padding-bottom: $fade-gradient-height;
        }

        @include fade-gradient($fade-gradient-height);
      }
    }
  }
}
