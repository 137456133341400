@import '../../styles/variables.scss';

.organization-detail {
  header {
    background-color: $gray-50;
    padding: $px-37 $px-80 $px-60 $px-80;
    & > div {
      display: flex;
      width: 100%;
      & > div {
        min-width: 20%;
        font-family: 'Public Sans Medium';
        & > div {
          font-size: $px-14;
          line-height: $px-20;
          color: $gray-500;
          padding: $px-8 0;
        }
      }
    }
  }
  .k-tabstrip {
    padding: 0 $px-80 $px-60 $px-80;
    .search {
      margin: $px-10 auto $px-24;
    }
    .k-grid {
      td.creator {
        font-family: 'Public Sans Regular';
        font-size: $px-14;
        line-height: $px-20;
        color: $gray-700;
        img {
          float: left;
          margin-right: $px-8;
        }
        div {
          padding: $px-3 0;
          font-family: 'Public Sans Regular';
          font-size: $px-12;
          line-height: $px-18;
          color: $gray-500;
        }
      }
    }
  }
}
