
@import '../../../../styles/variables.scss';

.question-content {
  .sections {
    margin: $px-38 0;

    .distractor {
      align-items: center;
      padding: $px-10 0;
      border-bottom: 1px $gray-300 solid;
    
      &:first-of-type {
        border-top: 1px $gray-300 solid;
      }
    
      .distractor-text {
        flex-grow: 1;
        padding: $px-5;
        height: $px-20;
      
        &.default {
          color: $gray-500;
        }
      }
    
      .k-radio,
      .k-checkbox,
      .k-button {
        margin: $px-5;
      }

      .k-button {
        padding: unset;
        width: $px-36;
        height: $px-36;
      }
    }
  }
}