@import '../../../styles/variables.scss';

.no-record-found {
  position: relative;
  bottom: 0;
  transform: translateY(-50%);
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  color: $gray-500;
  display: flex;
  width: 350px;
  flex-direction: column;
  font-size: $px-14;
  justify-content: flex-start;
  text-align: center;
  line-height: $px-20;
  :global {
    h4 {
      font-family: 'Public Sans Medium';
      font-size: $px-18;
      color: $gray-900;
      margin: $px-22 0 $px-16 0;
    }
    .k-button {
      margin-top: $px-24;
    }
  }
}
