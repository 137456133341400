@import '../../styles/variables.scss';

.customers {
  background-color: $gray-50;
  padding: $px-37 $px-80 $px-60 $px-80;

  // Note: Can be separated component
  .cards {
    display: flex;
    justify-content: space-between;

    // Note: Can be separated component
    .card {
      align-items: flex-start;
      background: $white;
      border: $px-1 solid $gray-300;
      box-shadow: 0 $px-1 $px-3 rgba(16, 24, 40, 0.1),
        0 $px-1 $px-2 rgba(16, 24, 40, 0.06);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: $px-8;
      isolation: isolate;
      order: 0;
      padding: $px-24;
      &:nth-child(2) {
        margin: 0 $px-24;
      }
      .label {
        background: $success-50;
        color: $success-700;
      }
      .title {
        color: $gray-500;
        font-size: $px-14;
        font-style: normal;
        font-weight: 500;
        line-height: $px-20;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        button {
          border: none;
          background: none;
        }
      }
      .number {
        font-family: 'Public Sans Semi Bold';
        font-size: $px-36;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -0.02em;
        line-height: $px-44;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        span {
          font-family: 'Public Sans Medium';
        }
      }
    }
  }
}
