@import '../../styles/variables.scss';

.top-bar {
  align-items: center;
  background: $white;
  border-bottom: $px-1 solid $gray-300;
  display: flex;
  gap: $px-10;
  padding: $px-4 $px-20;

  :global {
    input.plain {
      color: $gray-700;
      font-family: 'Public Sans Medium';
      padding: $px-10 $px-10;
    }
  }
}