@import '../../styles/variables';

.left-bar-template .footer {
  align-items: center;
  background: $gray-50;
  border-top: $px-1 solid $gray-300;
  bottom: $px-60;
  color: $gray-700;
  display: flex;
  font-family: 'Public Sans Medium';
  font-size: $px-14;
  justify-content: flex-start;
  line-height: $px-20;
  padding: $px-20 $px-20 $px-30 $px-20;
  position: fixed;
  width: $px-230;
  .k-dropdown-button {
    margin-right: $px-12;
  }
  div {
    div {
      font-size: $px-12;
      line-height: $px-18;
      color: $gray-500;
    }
  }
}
