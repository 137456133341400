@import '../../styles/variables.scss';

.top-bar-super {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: $px-7 $px-16;
  border-bottom: $px-1 solid $gray-300;

  :global {
    .logo {
      height: $px-24;
    }
  }
}
