// animation styles
$spin-cubic-bezier: spin 1.5s cubic-bezier(0.72, 1.88, 0.45, 0.71) infinite;

// colors
$gray-25: #fcfcfd;
$gray-50: #f9fafb;
$gray-100: #f2f4f7;
$gray-200: #eaecf0;
$gray-300: #d0d5dd;
$gray-400: #98a2b3;
$gray-500: #667085;
$gray-600: #475467;
$gray-700: #344054;
$gray-800: #1d2939;
$gray-900: #101828;

$primary-50: #f0f9ff;
$primary-100: #e0f2fe;
$primary-500: #0ba5ec;
$primary-600: #0086c9;
$primary-700: #026aa2;

$error-50: #fef3f2;
$error-100: #fee4e2;
$error-500: #f04438;
$error-600: #d92d20;
$error-700: #b42318;

$warning-50: #fffaeb;
$warning-100: #fef0c7;
$warning-500: #f79009;
$warning-600: #dc6803;
$warning-700: #b54708;

$success-50: #ecfdf3;
$success-100: #d1fadf;
$success-500: #12b76a;
$success-600: #039855;
$success-700: #027a48;

$blue-dark-50: #eff4ff;
$blue-dark-100: #d1e0ff;
$blue-dark-500: #2970ff;
$blue-dark-600: #155eef;
$blue-dark-700: #004eeb;

$purple-50: #f4f3ff;
$purple-100: #ebe9fe;
$purple-500: #7a5af8;
$purple-600: #6938ef;
$purple-700: #5925dc;

$fuchsia-50: #fdf4ff;
$fuchsia-100: #fbe8ff;
$fuchsia-500: #d444f1;
$fuchsia-600: #ba24d5;
$fuchsia-700: #9f1ab1;

$rose-50: #fff1f3;
$rose-100: #ffe4e8;
$rose-500: #f63d68;
$rose-600: #e31b54;
$rose-700: #c01048;

$yellow-50: #fefbe8;
$yellow-100: #fef7c3;
$yellow-500: #eaaa08;
$yellow-600: #ca8504;
$yellow-700: #a15c07;

$white: #ffffff;
$black: #000000;
$primary: $primary-600;
$secondary: $gray-500;

// spacing
$-px-54: -3.375rem;
$-px-4: -0.25rem;
$-px-5: -0.313rem;
$-px-2: -0.125rem;
$-px-1: -0.063rem;
$px-1: 0.063rem;
$px-2: 0.125rem;
$px-3: 0.188rem;
$px-4: 0.25rem;
$px-5: 0.313rem;
$px-6: 0.375rem;
$px-7: 0.438rem;
$px-8: 0.5rem;
$px-9: 0.563rem;
$px-10: 0.625rem;
$px-12: 0.75rem;
$px-14: 0.875rem;
$px-15: 0.938rem;
$px-16: 1rem;
$px-18: 1.125rem;
$px-20: 1.25rem;
$px-22: 1.375rem;
$px-24: 1.5rem;
$px-26: 1.625rem;
$px-28: 1.75rem;
$px-30: 1.875rem;
$px-32: 2rem;
$px-36: 2.25rem;
$px-37: 2.375rem;
$px-38: 2.375rem;
$px-40: 2.5rem;
$px-44: 2.75rem;
$px-46: 2.875rem;
$px-48: 3rem;
$px-50: 3.125rem;
$px-56: 3.5rem;
$px-60: 3.75rem;
$px-61: 3.813rem;
$px-64: 4rem;
$px-67: 4.188rem;
$px-68: 4.25rem;
$px-70: 4.375rem;
$px-72: 4.5rem;
$px-80: 5rem;
$px-85: 5.313rem;
$px-96: 6rem;
$px-100: 6.25rem;
$px-112: 7rem;
$px-120: 7.5rem;
$px-128: 8rem;
$px-138: 8.625rem;
$px-140: 8.75rem;
$px-160: 10rem;
$px-178: 11.125rem;
$px-230: 14.375rem;
$px-260: 16.25rem;
$px-280: 17.5rem;
$px-360: 22.5rem;
$px-410: 25.625rem;
$px-480: 30rem;
$px-510: 31.875rem;
$px-538: 33.625rem;
$px-550: 34.375rem;
$px-600: 37.5rem;
$px-680: 42.5rem;
$px-856: 53.5rem;
$px-900: 56.25rem;
$px-1024: 64rem;
$px-1440: 90rem;

$scrollbar-width: 10px;
$fade-gradient-height: 50px;