@import '../../../../styles/variables.scss';

.detail-top-bar {
  border-bottom: 1px $gray-300 solid;
  padding: $px-10 $px-20;
}

.question-content {
  padding: $px-37 $px-138 $px-96 $px-178;

  .tox.tox-tinymce {
    border: $px-1 $gray-300 solid;
    border-radius: 0;

    .tox-editor-header {
      border-bottom: $px-1 $gray-300 solid;
      box-shadow: none;
      transition: none;
    }
  }

  .editor-buttons {
    padding-top: $px-20;
    height: $px-40;
  }
}