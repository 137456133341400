@font-face {
  font-family: 'Public Sans Bold';
  src: url('../../public/fonts/publicSans/PublicSans-Bold.woff2')
      format('woff2'),
    url('../../public/fonts/publicSans/PublicSans-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Public Sans Medium';
  src: url('../../public/fonts/publicSans/PublicSans-Medium.woff')
      format('woff'),
}
@font-face {
  font-family: 'Public Sans Regular';
  src: url('../../public/fonts/publicSans/PublicSans-Regular.woff2')
      format('woff2'),
    url('../../public/fonts/publicSans/PublicSans-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Public Sans Semi Bold';
  src: url('../../public/fonts/publicSans/PublicSans-SemiBold.woff2')
      format('woff2'),
    url('../../public/fonts/publicSans/PublicSans-SemiBold.woff') format('woff');
}
