@import '../../styles/variables';

.switch-organization {
  display: flex;
  align-items: center;
  padding: $px-32 $px-24 0 $px-24;
  justify-content: space-between;
  & > div {
    font-family: 'Public Sans Medium';
    font-size: $px-14;
    line-height: $px-20;
    color: $gray-700;
    display: flex;
    & > img {
      margin-right: $px-12;
    }
    & > div {
      & > div {
        font-family: 'Public Sans Regular';
        font-size: $px-12;
        line-height: $px-18;
        color: $gray-500;
      }
    }
  }
  .k-dropdown-button {
    button {
      background: none;
      border: none;
    }
  }
}
