@import '../../../styles/variables.scss';

.organization-settings {
  :global {
    .display-flex {
      align-items: center;
      color: $gray-700;
      font-family: 'Public Sans Medium';
      font-size: $px-14;
      gap: $px-22;
      margin: 18px 0;
      align-items: center;

      span.overflow-elipsis {
        max-width: $px-260;
        display: block;
      }

      & > div {
        width: $px-480;
        p {
          font-family: 'Public Sans Regular';
          color: $gray-500;
        }
      }

      .k-input {
        width: $px-480;
      }

      .save-close {
        max-width: $px-40;
        margin-left: -$px-12;
      }
    }
  }
}
