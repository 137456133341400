@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins';

.test-detail {
  :global {
    .main {
      display: flex;
      // viewport height - header
      min-height: calc(100vh - $px-61);
      flex-direction: row;
      justify-content: space-between;

      .detail-settings {
        padding: 0;
        position: relative;
        // override globals.scss setting to make up for missing padding
        width: $px-280;
        @include fade-gradient($fade-gradient-height);

        &::after {
          // specific changes to the gradient (footer + view switch height)
          bottom: calc($px-67 + $px-60);
        }

        .k-input-md .k-input-values {
          gap: $px-10 !important;
        }

        & > div:not(.view-switch) {
          padding: $px-16 $px-16 0;
          display: flex;
          flex-direction: column;
        }        

        &.left {
          border-left: none;
          border-right: $px-1 $gray-300 solid;

          & > div:not(.view-switch) {
            position: relative;
            // viewport height - ((header + footer) - padding - view switch height)
            height: calc(100vh - $px-128 - $px-16 - $px-60);
            overflow-y: auto;
            @include scrollbar();

            .section-header {
              flex: 1;
            }

            .k-listbox {
              width: auto;

              .k-list-scroller {
                border: none;
              }
            }

            ul {
              display: flex;
              flex-direction: column;
              list-style: none;
              margin: 0;
              padding: 0;

              &.k-list-ul {
                padding-bottom: $fade-gradient-height;
              }

              li {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                align-items: center;
                padding: $px-8 $px-12;
                gap: $px-8;
                border-radius: $px-6;

                svg {
                  width: $px-22;
                  height: $px-22;

                  path {
                    stroke: $gray-500;
                  }
                }

                .k-button {
                  color: $gray-500;

                  .k-button-text {
                    display: flex;
                    align-items: center;
                    gap: $px-12;
                  }
                }

                &.active {
                  background-color: $primary-50;

                  .k-button {
                    color: $primary-600;

                    path {
                      stroke: $primary-500;
                    }
                  }
                }

                .k-button.delete {
                  display: none;
                }

                &:hover {
                  .k-button.delete {
                    display: block;
                  }
                }
              }
            }
          }

          .view-switch {
            padding: $px-16;
            align-self: center;
            z-index: 1;
          }

          &.folded {
            width: $px-140 !important;
          }
        }
      }

      .content {
        padding: $px-20 $px-30 $px-96;
        display: flex;
        flex: 1;
        flex-direction: column;
        background: $gray-50;

        .k-button {
          background: $primary-600;
          color: $white;

          .display-flex {
            align-items: center;
            gap: $px-12;
            font-family: 'Public Sans Regular';

            svg {
              width: $px-10;
              height: $px-10;

              path {
                fill: $white;
              }
            }
          }
        }

        h1 {
          align-items: center;
          color: $gray-900;
          display: flex;
          flex-direction: row;
          font-family: 'Public Sans Semi Bold';
          font-size: $px-20;
          justify-content: space-between;
          line-height: $px-30;
          margin: 0 0 $px-20 0;
        }

        .no-record-found {
          top: 20%;
        }

        .k-listbox {
          width: 100%;
          height: 100%;

          .k-list-scroller {
            background: transparent;
            border: none;
          }
        }

        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          display: flex;
          flex-direction: column;
          gap: $px-20;

          li {
            padding: $px-20 $px-30 !important;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0;
            background: $white;
            border: $px-1 solid $gray-300;
            border-radius: $px-16;
            font-family: 'Public Sans Regular';
            font-size: $px-14;
            line-height: $px-20;
            color: $gray-500;

            > .display-flex {
              align-items: center;
              display: flex;
              justify-content: space-between;
              width: 100%;

              .k-button {
                opacity: 0;
                pointer-events: none;

                img {
                  max-width: $px-20;
                }
              }
            }

            &:hover {
              .k-button {
                opacity: 1;
                pointer-events: auto;
              }
            }
          }
        }
      }
    }
  }
}
