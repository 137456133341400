﻿@mixin round-borders ($radius) {
    border-radius: $radius;
}

@mixin scrollbar() {
    // scrollbar
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: #ddd;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 100vw;
        background-color: #aaa;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #999;
    }
}

@mixin hide-distractor-keys-shared($size, $top) {
    border-radius: 0;
    color: transparent;
    font-size: 0px;
    border: none !important;
    width: $size;
    height: $size;
    top: $top;
}

/**
 * Responsive breakpoint manager - shamelessly modified from sass-guidelin.es
 * @param {String} $breakpoint
 * @requires $breakpoints
 */
@mixin breakpoint($breakpoint) {
    $raw-query: map-get($breakpoints, $breakpoint);

    @if $raw-query {
        $query: if(
          type-of($raw-query) == 'string',
          unquote($raw-query),
          inspect($raw-query)
        );

        @media #{$query} {
          @content;
        }
    } @else {
        @error 'No value found for `#{$breakpoint}`. '
            + 'Please make sure it is defined in `$breakpoints` map.';
    }
}