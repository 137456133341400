@import '../../../styles/variables.scss';

.results {
  :global {
    h1,
    .k-tabstrip,
    .display-flex {
      width: $px-1024;
      margin: $px-30 auto;
    }
    .k-grid {
      padding-bottom: $px-67;

      .display-flex {
        width: auto;
        margin: auto;
      }
    }
    h1 {
      display: flex;
      justify-content: space-between;
      .k-button {
        margin: 0 $px-4;
      }
      .result-buttons {
        display: flex;
        margin-left: auto;
      }
    }
    .k-progressbar {
      width: 30%;
    }
    // Exam preview
    .exam-preview {
      h1 {
        button {
          img {
            height: $px-12;
            margin: $px-7;
            min-height: auto !important;
            min-width: auto !important;
            width: $px-12;
          }
          svg {
            height: $px-9;
            margin-left: $px-8;
            width: $px-10;
          }
        }
      }
      .k-tabstrip {
        .display-flex {
          gap: $px-8;
        }
        .k-tabstrip {
          .k-tabstrip-items-wrapper {
            margin-left: 14px;
            border: none;
          }
          .proctoring {
            display: flex;
            justify-content: space-between;
            width: $px-1024;
            #video-box {
              video {
                border-radius: $px-16;
                width: 500px;
              }
              .video-control-bar {
                padding-bottom: $px-10;
                .controls {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  & > div {
                    display: flex;
                    align-items: center;
                    gap: $px-8;
                    .k-button {
                      border: none;
                    }
                    .k-dropdownlist {
                      width: 75px;
                      padding: 0;
                    }
                    .k-slider {
                      width: 70px;
                      margin-right: 20px;
                    }
                  }
                }
                .time {
                  text-align: center;
                }
                .sliderContainer {
                  position: relative;
                }
                .dot {
                  background: red;
                  border-radius: 50%;
                  width: 6px;
                  height: 6px;
                  position: absolute;
                  bottom: 25px;
                  z-index: 2;
                  margin-left: 0px;
                  .tooltipText {
                    position: absolute;
                    right: -24px;
                    width: auto;
                    background: rgba(97, 97, 97, 0.92);
                    color: white;
                    text-align: center;
                    border-radius: 4px;
                    font-size: 0.6875rem;
                    padding: 5px 8px;
                    margin-top: 14px;
                    transform-origin: center top;
                    transform: none;
                    opacity: 0;
                    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                      transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                  }
                }
                .dot:hover {
                  .tooltipText {
                    opacity: 1;
                  }
                }
                .ui-time-seek-slider .main {
                  background-color: $gray-100;
                }
                .ui-time-seek-slider .connect {
                  background-color: $primary-500 + ' !important';
                }
                .ui-time-seek-slider .seek-hover {
                  background-color: $primary-100 + ' !important';
                }
                .ui-time-seek-slider .handler {
                  background-color: $primary-500 + ' !important';
                }
              }
            }
            .identification {
              display: flex;
              width: 500px;
              img {
                width: 50%;
                cursor: zoom-in;
              }
              .darkbox {
                position: fixed;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.7);
                width: 100vw;
                height: 100vh;
                z-index: 1000000;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                img {
                  cursor: zoom-out;
                  width: 80vw;
                  height: auto;
                  z-index: 1000000;
                }
              }
            }
            .violations-list {
              border-radius: $px-16;
              border: 1px solid $gray-300;
              width: 500px;
              ul {
                margin: 0;
                padding: 24px;
                list-style-type: none;
                li {
                  cursor: pointer;
                  align-items: center;
                  border-bottom: 1px solid $gray-100;
                  display: flex;
                  padding: 14px;
                  .time {
                    width: 80px;
                  }
                }
              }
            }
          }
        }
      }
      .questions-answers {
        & > div.display-flex {
          justify-content: space-between;
          & > div {
            width: 50%;
            border: $px-1 solid $gray-100;
            padding: $px-20;
            border-radius: $px-8;
            font-size: $px-14;
            color: $gray-500;
            h4 {
              color: $gray-900;
              font-family: 'Public Sans Medium';
              font-size: $px-18;
              margin: 0 0 8px 0;
            }
            span {
              display: block;
              margin: 22px 0 0 0;
              strong {
                color: $gray-900;
                font-family: 'Public Sans Medium';
              }
            }
            &.status {
              h4 {
                color: $error-500;
              }
              .k-selected {
                border-color: $error-500 !important;
                background-color: $error-500 !important;
              }
              &.passed {
                h4 {
                  color: $success-500;
                }
                .k-selected {
                  border-color: $success-500 !important;
                  background-color: $success-500 !important;
                }
              }
            }
            .k-progressbar {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
