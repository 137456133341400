.item {
    margin-bottom: 15px;
    padding-top: 30px;
    border-radius: 10px;
    background-color: $assignment_bgcolor;
    page-break-inside: avoid;
    padding-bottom: 30px;
    font-size: 16px;
    line-height: 1.5;

    &:hover {
        background-color: $assignment_bgcolor;
    }

    .ItemImage {
        max-width: 100%;
    }

    .floatImageLeft {
        margin-right: 10px;
    }

    .floatImageRight {
        margin-left: 10px;
    }

    .item-number {
        font-size: 22px;
        margin: 0px 0px 5px 0px;
        color: $item_number_color;
        padding-left: 30px;
        padding-right: 30px;
    }

    .rich-content {
        overflow-x: visible;
        overflow-y: visible;

        .red {
            background: none;
            color: $color_magenta;
            font-weight: bold;
        }

        .term {
            font-style: italic;
        }

        .mathTerm {
            font-family: 'Times New Roman', Times, serif;
            font-style: italic;
            font-weight: bold;
            font-size: 120%;
        }

        .chemTerm {
            font-family: 'Times New Roman', Times, serif;
            font-size: 120%;
        }

        .biblioCite {
            font-size: xx-small;
        }

        .boldtext {
            font-weight: bold;
        }

        .italictext {
            font-style: italic;
        }

        .underlinetext {
            text-decoration: underline;
        }

        .term {
            font-style: normal;
        }

        .unbold {
            font-weight: normal !important;
        }

        p {
            margin-bottom: 8px;
        }

        em {
            font-weight: bold;
            font-style: normal;
        }

        table {
            display: block;
            width: 100%;
            overflow-x: auto;
            border-collapse: collapse;

            &.compareWrapper {
                display: table;
                width: auto;
            }
        }

        table td, table th {
            border: 1px solid black;
        }

        table.noBorder td, table.noBorder th {
            border: none;
        }

        table.borderedTable {
            border-style: solid;
            border-color: black;
            border-width: 0 !important; // override inline styling
        }

        table th, table td {
            padding: 5px;
        }

        table.compareWrapper {
            width: 100%;
            min-height: 20px;
            margin: 0px;
            padding: 0px;
            border: none !important;
            table-layout: fixed;
        }

        table.compareWrapper td {
            width: 45%;
            height: 100%;
            border: 2px solid #cccccc !important;
            word-wrap: break-word;
        }

        table.compareWrapper td.blankTD {
            width: auto;
            border: none !important;
        }

        &.text-item {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    .answerable-item {
        position: relative;

        .assignment, .item-instruction {
            @include round-borders(4px);
            padding: 10px 30px 10px 50px;
            margin-bottom: 3px;

            & > p {
                margin-top: 3px;

                textarea {
                    max-width: 80%;
                    vertical-align: text-top;
                }
            }
        }

        .filling-instruction {
            margin: 5px 40px 5px 30px;
            padding: 3px 15px 3px 15px;
            font-style: italic;
            color: #444;
            border: 1px solid transparent;
            //Schválně vypnuto, kvůli lokalizaci.
            display: none;
        }
    }

    &.implicit-instruction {
        background: $instruction_background_color;
        border: 1px solid $instruction_color;
        box-sizing: border-box;
        border-radius: 4px;
        page-break-after: avoid;

        .item-number {
            color: $instruction_color;
        }
    }

    &.instruction {
        background: $instruction_background_color;
        border: 1px solid $instruction_color;
        box-sizing: border-box;
        border-radius: 4px;
        page-break-after: avoid;
        color: $instruction_color;
        page-break-after: avoid;
    }

    input[type=checkbox], input[type=radio] {
        transform: scale(1.3);
        cursor: pointer;
    }

    input[type=text] {
        border-radius: 3px;
        border-width: 1px;
        font-size: 14px;
    }

    .item-loader {
        position: absolute;
        left: 30px;
        right: 30px;
        top: 0;
        bottom: 0;
        z-index: 999;
        cursor: wait;

        .item-loader-animation {
            position: relative;
            top: -20px;
            width: 100%;
            height: 2px;
            animation: rotate 3s infinite linear;
            -webkit-animation: rotate 3s infinite linear;
            background: -webkit-gradient(linear, left bottom, right bottom, color-stop(0%,transparent), color-stop(10%,transparent), color-stop(25%,$primary_bgcolor), color-stop(55%,transparent), color-stop(100%,transparent));
        }

        .item-loader-text {
            position: relative;
            top: -20px;
            text-align: center;
            color: $primary_shadow_color;
        }

        @-webkit-keyframes rotate {
            from {
                background-position: -2000px;
            }

            to {
                background-position: 0px;
            }
        }

        @keyframes rotate {
            from {
                background-position: -2000px;
            }

            to {
                background-position: 0px;
            }
        }
    }
}
