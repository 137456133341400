@import '../../styles/variables';

.log-out {
  color: $gray-700;
  font-family: 'Public Sans Bold';
  font-size: $px-14;
  display: flex;
  flex-direction: row;
  align-items: center;
  // Roles
  .role {
    background: $primary-100;
    font-family: 'Public Sans Medium';
    padding: $px-2 $px-8 $px-2 $px-10;
    margin: $px-4 $px-2;
    font-size: $px-12;
  }
}
