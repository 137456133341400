@import '../../styles/variables';

.log-out-button.k-dropdown-button > button {
  height: $px-32;
  width: $px-32;
  color: $gray-700;
  border: 1px $gray-300 solid;
  border-radius: 50%;
  background: $gray-50;

  &:hover {
    background: $gray-300;
  }
}
