@import '../../styles/variables';

.footer {
  align-items: center;
  background: $gray-50;
  border-top: 1px solid $gray-300;
  display: flex;
  font-size: $px-14;
  justify-content: space-between;
  max-width: $px-1440;
  padding: $px-15;
  width: 100%;
  &.fixed {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: $px-15 $px-60;
    z-index: 100;
    box-sizing: border-box;
  }
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
    align-items: center;
    gap: $px-32;
    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      &.language {
        font-family: 'Public Sans Medium';
        img {
          margin-right: $px-10;
        }
        .k-button {
          background: none;
          border: none;
          svg {
            width: $px-12;
            height: $px-7;
            fill: none;
            stroke: $gray-500;
          }
        }
      }
    }
  }
}
