﻿.open-item {
    .ui-keyboard-input {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAANCAIAAADJ8LI3AAAAXElEQVQokeWSMQoAMQgEV0nnQ+19iH0eau0VQoocHJiUt4WgMI6FlJm4yABgZhHRJUXE3RnAAbwoXv2cs1UrlJmqeuCvRX/3j/fom9lkO9+9hQGISIupFEWX//8AaXFTSoy90LUAAAAASUVORK5CYII=) top right no-repeat;
        background-position-y: 4px;
        background-color: #eee;
        min-height: 15px;
        cursor: pointer;
    }

    textarea {
        text-indent: 5px;
        border: 1px solid $minor_color;
        box-sizing: border-box;
        border-radius: 4px;
        height: 100px;
        min-width: 450px;
        max-width: 100%;
    }

    .options {
        border: 0px solid black;
    }

    .item-isntruction {
        padding: 10px 30px 10px 50px;
    }

    .open-item-instruction {
        background-color: #f0eeee;
        border-radius: 5px;
        color: #444;
        font-size: 13px;
        margin-bottom: 3px;
        padding: 10px 15px;
    }

    input {
        border: 1px solid $minor_color;
        box-sizing: border-box;
        border-radius: 4px;
        text-indent: 3px;
    }
}
