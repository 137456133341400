@import '../styles/variables.scss';

.public {
  display: flex;
  background: $gray-50;
  box-sizing: border-box;
  flex-direction: column;
  height: 100vh;
  padding: $px-26 $px-112;
  
  .link {
    color: $primary-700;
    font-family: 'Public Sans Medium';
    margin-left: 0.188rem;
    text-decoration: none;
  }

  > .content {
    padding: $px-128 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: $gray-50;
  }
}
