/*********** KENDO ***********/
// Button
.k-button {
  align-items: center;
  padding: $px-8 $px-14;
  gap: $px-8;
  border-radius: $px-8;
  background: #ffffff;
  border: $px-1 solid $gray-300;
  font-family: 'Public Sans Medium';
  &.primary {
    color: $white;
    background: $primary-600;
    border: $px-1 solid $primary-600;

    &:hover {
      background: $primary-700;
    }
  }
  &:disabled {
    pointer-events: auto;
    cursor: not-allowed;

    > .k-button-icon {
      opacity: 0.6;
    }
  }
}
.k-button-solid-base:focus,
.k-button-solid-base.k-focus {
  box-shadow: none;
}

// Icon button - icon reset
.k-icon-button {
  &.reset-icon-size {
    > .k-button-icon {
      min-width: auto;
      min-height: auto;
    }
  }

  &.save-close {
    padding: 12px;

    > .k-button-icon {
      height: 14px;
    }
  }
}

// Datepicker
.k-datepicker {
  flex-direction: row-reverse;
  border-radius: $px-8;
  margin: $px-5 0;
  .k-button {
    border: none;
    margin: 0 -6px 0 12px;
    .k-icon {
      &.k-i-calendar::before {
        content: ' ';
        width: $px-20;
        height: $px-20;
        background: url('../../public/images/calendar.svg') no-repeat center
          center;
      }
    }
  }
}
// Dialog
.k-dialog-wrapper {
  .k-dialog {
    color: $gray-900;
    background: $white;
    border-radius: $px-12;
    box-shadow: 0 $px-20 $px-24 $-px-4 rgba(16, 24, 40, 0.08),
      0 $px-8 $px-8 $-px-4 rgba(16, 24, 40, 0.03);
    gap: $px-24;
    padding: $px-40;
    width: 60vw;
    min-width: $px-680;
    max-width: $px-856;
    max-height: 90vh;

    .k-dialog-titlebar {
      background-color: transparent;
      border: none;
      padding: 0;
      margin: 0;

      .k-dialog-title {
        font-family: 'Public Sans Medium';
        font-size: $px-18;
        line-height: $px-28;
        color: $gray-900;
        padding: 0;
        margin: 0;
      }

      .k-i-x::before {
        color: $gray-900 !important;
        font-size: $px-20;
      }
    }

    .k-dialog-content {
      padding: 0;

      &.k-window-content {
        @include scrollbar();
        @include fade-gradient($fade-gradient-height);

        &::after {
          position: sticky;
          width: 100%;
        }
      }

      strong {
        font-family: 'Public Sans Medium';
        color: $gray-700;
      }

      .k-label {
        margin-bottom: $px-6;
        font-family: 'Public Sans Medium';
        line-height: $px-20;
        color: $gray-700;
      }

      input.k-input,
      .k-multiselect,
      .k-radio-list,
      .k-textarea {
        margin: $px-6 0 $px-16;
      }

      .k-radio-list.create-question-type {
        .k-radio-item {
          position: relative;
          flex-direction: row-reverse;
          align-items: flex-start;
          margin-bottom: $px-8;
          padding: $px-16;
          height: $px-36;
          border: $px-1 solid $gray-300;

          label {
            display: block !important;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: $px-68;
          }

          div {
            margin-left: $px-12;

            p {
              margin: 0;
              font-size: $px-12;
              color: $gray-500;

              &:first-child {
                font-family: 'Public Sans Medium';
                font-size: $px-14;
                color: $gray-700;
                line-height: $px-20;
              }
            }
          }

          .k-radio {
            margin-left: auto;
          }
        }
      }
    }

    .k-dialog-buttongroup {
      border: none;
      display: flex;
      justify-content: flex-end;

      &.k-justify-content-stretch > * {
        flex: none;
      }

      button {
        width: auto;
      }
    }
  }

  &.with-navigation {
    .k-dialog {
      .k-dialog-content {
        display: flex;
        gap: $px-112;
      }
    }
  }
}

// Dropdown
.k-dropdownlist {
  background: $white;
  border-radius: $px-8;
  border: $px-1 solid $gray-300;
  color: $gray-900;
  font-size: $px-14;
  font-weight: 400;
  gap: $px-8;
  margin: $px-5 0;
  padding: $px-4 $px-8;
  &::placeholder {
    color: $gray-500;
    font-size: $px-14;
  }
}
// Chip
.k-chip-solid-base {
  background: $gray-100 !important;
  border-radius: $px-6 !important;
  border: none;
}
// List
.k-list-item.k-selected {
  background-color: $white;
  color: $gray-500;
  cursor: auto;
  &:hover {
    background-color: $white;
    color: $gray-500;
  }
}

// Listbox
.k-listbox {
  height: auto;
}

// Grid
.k-grid {
  width: 100%;

  // hide scrollbars
  .k-grid-header {
    padding-inline-end: 0;
    border-color: $gray-300;

    .k-grid-header-wrap {
      border-width: 0;
      margin-right: 0;
    }
  }

  .k-grid-container {
    min-height: $px-538;

    .k-grid-content {
      overflow-y: auto;
    }
  }

  &.classrooms-list {
    .k-grid-container {
      min-height: $px-510;
    }
  }
}

// Grid - Remove backgrounds
.k-grid button,
.k-grid-add-row,
.k-grid-footer,
.k-grid tr.k-alt,
.k-grid-header,
.k-grouping-header,
.k-pager-wrap,
.k-grid-table tr {
  background: transparent;
}
// Grid - Borders
.k-grid,
.k-grid button,
.k-grid th,
.k-grid-table td,
.k-grid-pager {
  border: none;
}
// Grid - Column title
.k-header {
  .k-column-title {
    color: $gray-500;
    font-family: 'Public Sans Medium';
    font-size: $px-12;
    line-height: $px-18;
    padding: $px-12 $px-2 $px-12 0;

    .margin-0-checkbox {
      .k-checkbox {
        margin: 0;
      }
    }
  }
  .k-checkbox {
    margin-right: $px-14;
  }
}
.k-cell-inner > .k-link {
  padding-left: 0 !important;
  padding: 0 $px-12;
}
.k-grid table {
  width: 100% !important;
  .k-master-row.selected {
    background: $primary-50 !important;
  }
}
.k-grid-table {
  tbody {
    & tr.k-master-row:hover {
      background-color: $gray-50;
    }
  }
  td {
    color: $gray-500;
    font-size: $px-14;
    line-height: $px-20;
    border-bottom: $px-1 solid $gray-300;
    padding: $px-16 $px-24 $px-16 0;
    gap: $px-12;

    &:last-child {
      text-overflow: clip;
    }
  }
}
// Grid - Hide pager info
.k-pager-info {
  display: none;
}
// Grid - Pager
.k-grid-pager {
  display: flex;
  justify-content: space-between;
  border-top: $px-1 solid $gray-300;
  padding: $px-16 0;
  background: $white;
  .k-pager-last,
  .k-pager-first {
    display: none;
  }
  .k-link {
    border-radius: $px-8;
    font-size: $px-14;
    line-height: $px-20;
    text-align: center;
    color: $gray-500;
    padding: $px-20;
    &.k-selected,
    &:hover {
      font-family: 'Public Sans Medium';
      color: $gray-700;
      background: $gray-100;
    }
    &:focus {
      box-shadow: none;
    }
    .k-i-arrow-60-left,
    .k-i-arrow-60-right {
      &:before {
        background-size: cover;
        content: '';
        height: $px-14;
        width: $px-14;
      }
    }
    .k-i-arrow-60-left {
      &:before {
        background: url('../../public/images/arrowLeft.svg');
      }
    }
    .k-i-arrow-60-right {
      &:before {
        background: url('../../public/images/arrowRight.svg');
      }
    }
  }
}
// Input
input.k-input {
  background: $white;
  border-radius: $px-8;
  border: $px-1 solid $gray-300;
  color: $gray-900;
  font-size: $px-14;
  gap: $px-8;
  margin: $px-5 0;
  padding: $px-10 $px-14;
  &::placeholder {
    color: $gray-500;
    font-size: $px-14;
  }
  &.search:before {
    content: '';
    background: url('../../public/images/search.svg') center center no-repeat !important;
  }
}
input.k-input-inner {
  color: $gray-900;
  padding: $px-10 $px-14 !important;
  &::placeholder {
    color: $gray-500;
  }
}
.k-input-value-text {
  color: $gray-900;
  font-size: $px-14;
}
.k-checkbox-md {
  width: $px-20;
  height: $px-20;
}
// Label
.k-label {
  color: $gray-500;
}
// Notifications
.k-notification-group{
  align-items: 'center';
  bottom: 20px;
  width: 100vw;
  z-index: 10000;
  .k-notification {
    color: white;
    padding: 6px 16px;
    font-size: 14px;
    .k-i-close {
      margin: auto auto auto 12px;
      opacity: 0.6;
    }
    .k-notification-wrap {
      align-items: center;
    }
    &.k-notification-error {
      border-color: $error-700;
      background-color: $error-700;
    }
    &.k-notification-success {
      border-color: $success-700;
      background-color: $success-700;
    }
    .k-notification-warning {
      border-color: $warning-700;
      background-color: $warning-700;
    }
  }
}

// Popup
.k-popup {
  border-radius: $px-8;
  padding: $px-12;

  &.k-list {
    border-color: $gray-300;
  }

  button {
    background: none;
    border: none;
    &:hover {
      background: transparent;
    }
    &.k-button-solid-primary{
      background: $primary-600;
    }
  }
  span {
    box-shadow: none !important;
  }
  .k-button-text {
    display: inline-flex;
    align-items: center;
    img {
      width: 18px;
      height: 18px;
      margin: 0 10px;
    }
  }
  .k-filter-menu {
    .k-actions {
      .k-button-solid-primary {
        background: $primary-500 !important;
        color: $white !important;
        cursor: pointer !important;
        filter: none !important;
        opacity: 1 !important;
        pointer-events: auto !important;
      }
    }
  }
  .k-menu-link:hover {
    background-color: $gray-100 !important;
    border-radius: 8px;
  }
  .k-menu-group .k-item > .k-link-menu-link:active {
    background: transparent;
  }
}
// Stepper
.k-stepper {
  .k-step-indicator {
    background-color: $gray-200;
    border: $px-10 solid $gray-50 !important;
  }
  .k-step-current {
    .k-step-indicator {
      background-color: $primary-600;
      border: $px-10 solid $primary-50 !important;
    }
  }
  .k-step-done {
    .k-step-indicator {
      background: url('../../public/images/done.svg') no-repeat center center;
      background-color: $primary-50;
      border: none !important;
      background-color: #f0f9ff;
    }
  }
  .k-step-list-vertical {
    .k-step {
      min-height: $px-44;
    }
  }
}
// Tabs
.k-tabstrip {
  .k-tabstrip-items-wrapper {
    border-color: $gray-300;
    .k-item {
      border: none;
      padding-bottom: $px-12;
      .k-link {
        color: $gray-500;
        padding: $px-6 $px-16;
        .label {
          display: inline-flex;
          border-radius: $px-16;
          background: $gray-50;
          color: $gray-700;
          font-size: $px-12;
        }
      }
      &.k-active {
        background: transparent;
        border-bottom: $px-2 solid $primary-700;
        .k-link {
          color: $primary-700;
          font-family: 'Public Sans Medium';
          .label {
            background: $primary-50;
            color: $primary-700;
          }
        }
      }
    }
  }
  .k-content {
    overflow: hidden;
    border: none;
    padding: 0;
  }
}
// Switch
.k-switch {
  &.k-switch-sm {
    width: $px-36;
    height: $px-20;

    &.k-switch-on {
      .k-switch-thumb-wrap {
        left: calc(100% - $px-10);
      }
    }

    .k-switch-track {
      width: $px-36;
      height: $px-20;
    }

    .k-switch-thumb {
      width: $px-16;
      height: $px-16;
      filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.1));
    }

    &.state-switch {
      width: $px-128;

      &.k-switch-on {
        text-transform: capitalize;

        .k-switch-track {
          color: $gray-500;
          border-color: $gray-50;
          background-color: $gray-50;

          .k-switch-thumb-wrap {
            left: calc(100% - $px-32);

            .k-switch-thumb {
              border-color: $success-50;
            }
          }
        }
      }

      &.k-switch-off {
        text-transform: capitalize;

        .k-switch-thumb-wrap {
          left: $px-32;

          .k-switch-thumb {
            background-color: $warning-50;
            border-color: $warning-50;
          }
        }
      }

      .k-switch-label-on,
      .k-switch-label-off {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: $px-4;
        padding: $px-2 $px-8 $px-2 $px-10;
        width: $px-64;
        font-family: 'Public Sans Medium';
        font-size: $px-12;
        line-height: $px-20;
        text-transform: capitalize;

        &::before {
          content: '';
          display: block;
          float: left;
          width: $px-6;
          height: $px-6;
          margin-right: 0.125rem;
          border-radius: 50%;
          background-color: $gray-500;
        }
      }

      .k-switch-thumb {
        width: $px-64;
        height: $px-20;
        filter: none;
        text-transform: capitalize;
      }
    }
  }

  &.k-switch-off {
    .k-switch-track {
      border-color: $gray-100;
      background-color: $gray-100;
    }

    .k-switch-thumb-wrap {
      left: $px-10;
    }

    .k-switch-thumb {
      background-color: $white;
      border-color: $white;
    }
  }
}

// Multiselect
.k-multiselect {
  &.k-input-md,
  &.k-picker-md {
    .k-input-values {
      padding: $px-2 $px-12;
      gap: $px-6;

      .k-input-inner {
        padding-left: 0 !important;
      }

      .k-rounded-md {
        font-size: $px-12;
        font-family: 'Public Sans Medium';
        border-radius: $px-6;
        border-color: $gray-300;
        background-color: $gray-100;

        &.k-chip-solid-base {
          color: $gray-700;

          .k-chip-actions {
            color: $gray-400;

            .k-icon {
              font-size: $px-14;

              &:hover {
                color: $gray-700;
              }
            }
          }
        }
      }
    }
  }
}

// Components border color override
.k-button,
.k-checkbox,
.k-dropdownlist,
.k-input-solid,
.k-radio {
  border-color: $gray-300;
}
